<template>
  <v-card
    :loading="loading"
    class="deliverable-item bg-grey-gradient"
    v-bind="$attrs"
    @click="$emit('click', value)"
    @mouseover="() => $emit('mouseover', value)"
    @mouseleave="() => $emit('mouseleave', value)"
  >
    <div :class="{ active }" class="item-active-indicator"></div>

    <div class="deliverable-type-img control"></div>

    <div
      class="deliverable-status"
      :class="{
        primary: value.status.code === 'PUBLISHED',
        'teal darken-1': value.status.code === 'PENDING',
      }"
    >
      {{ value.status.name }}
    </div>
    <div>
      <v-card-title class="deliverable-title">
        <v-icon class="pa-2 mr-2" v-if="value.icon">{{ value.icon }}</v-icon>
        {{ value.name }}
        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="value.activity">
          <template v-slot:activator="{ on, attrs }">
            <span class="mr-16" v-bind="attrs" v-on="on">
              <v-icon color="primary" class="pa-2 mb-1"
                >mdi-clock-outline</v-icon
              >
              {{ durationFormatter(value.activity.avg_time, "m") }}</span
            >
          </template>
          <span
            >This is an average time for one activity session. <br />
            If you have a meeting it will be time for one session, <br />
            if this is a process then this is a time that needed to complete it.
          </span>
        </v-tooltip>

        <v-tooltip bottom v-if="value.document">
          <template v-slot:activator="{ on, attrs }">
            <span class="mr-16" v-bind="attrs" v-on="on">
              <v-icon color="primary" class="pa-2 mb-1"
                >mdi-clock-outline</v-icon
              >
              {{ durationFormatter(value.document.avg_time, "m") }}</span
            >
          </template>
          <span>This is an average DURATION of document creation. </span>
        </v-tooltip>
      </v-card-title>
      <!-- START DELIVERABLE TYPE LABELS -->
      <v-row>
        <v-col class="px-4 py-0">
          <v-tooltip bottom v-if="value.document && value.document.id">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                label
              >
                <v-icon class="mr-2">mdi-file-document-edit-outline</v-icon>
                DOCUMENT</v-chip
              >
            </template>
            <span
              >This is something that ADAAS Team will deliver as a document.<br />
              This is might be diagram, mind map, actual Document, or even
              spreadsheet.</span
            >
          </v-tooltip>

          <v-tooltip bottom v-if="value.activity && value.activity.id">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                label
              >
                <v-icon class="mr-2">mdi-cog-sync-outline</v-icon>
                ACTIVITY</v-chip
              >
            </template>
            <span
              >This is some activity that will be done by ADAAS Team as a part
              of service processing.<br />
              This is might be some kind of meetings, review, audit or anything
              else.</span
            >
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="value.activity && value.activity.type === 'MEETING'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-2"
                color="pink darken-3"
                v-bind="attrs"
                v-on="on"
                label
              >
                <v-icon class="mr-2">mdi-chat-processing-outline</v-icon>
                MEETING</v-chip
              >
            </template>
            <span
              >This is some activity that will be done by ADAAS Team as a part
              of service processing.<br />
              This is might be some kind of meetings, review, audit or anything
              else.</span
            >
          </v-tooltip>

          <v-tooltip
            bottom
            v-if="value.activity && value.activity.type === 'PROCESS'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-2"
                color="indigo darken-1"
                v-bind="attrs"
                v-on="on"
                label
              >
                <v-icon class="mr-2">mdi-magnify</v-icon>
                PROCESS</v-chip
              >
            </template>
            <span
              >This is a some sort of process like audit or analysis, or
              research.</span
            >
          </v-tooltip>

          <v-tooltip bottom v-if="value.sla && value.sla.id">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                label
              >
                <v-icon class="mr-2">mdi-handshake-outline</v-icon> SLA</v-chip
              >
            </template>
            <span
              >SLA types is something that ADAAS Team will guarantee during
              service processing. <br />
              This is might be response time, documents structure, or anything
              else that impacts delivery process.</span
            >
          </v-tooltip>

          <v-tooltip bottom v-if="value.artifact && value.artifact.id">
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                class="mr-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                label
              >
                <v-icon class="mr-2">mdi-code-json</v-icon> ARTIFACT</v-chip
              >
            </template>
            <span
              >Artifact type is some sort of deliverable that should be created
              and provided to the customer. <br />
              The main difference between artifact and document type that
              artifact is something more like code, or vision, or costs
              assessment, etc</span
            >
          </v-tooltip>
        </v-col>
      </v-row>
      <!-- END DELIVERABLE TYPE LABELS -->

      <v-divider class="mt-2 mr-4 ml-4"></v-divider>

      <v-card-text>
        <v-row class="justify-space-between">
          <v-col cols="12">
            <v-row align="center" class="mx-0"> </v-row>

            <div class="">
              <!-- <h3>{{ value.short_description }}</h3> -->
              <h4 class="brief">DEFINITION:</h4>
              <p class="simple-text deliverable-description">
                {{ cutFormatter(value.definition, 150) }}
              </p>
              <h4 class="brief">GOAL:</h4>
              <p class="simple-text">{{ cutFormatter(value.goal, 150) }}</p>
            </div>
          </v-col>
          <!-- <v-col cols="4"> </v-col> -->
        </v-row>
      </v-card-text>

      <!-- <div class="card-actions-bottom">
        <v-divider class="mx-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" text @click.stop.prevent="onOpen">
            Learn More
          </v-btn>
        </v-card-actions>
      </div> -->
    </div>
  </v-card>
</template>

<script>
import { FormatterHelper } from "../../../helpers/formatter.helper";

export default {
  props: {
    value: {},
    active: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      durationFormatter: FormatterHelper.timeDuration,
      cutFormatter: FormatterHelper.cutString,

      loading: false,
      show: false,
    };
  },
  components: {},
  methods: {
    onOpen() {
      this.$emit("onOpen", this.value);
    },
    onEdit() {
      this.$emit("onEdit", this.value);
    },
  },
};
</script>


<style lang="scss" scoped>
.deliverable-item {
  z-index: 2;
  overflow: hidden;
  .deliverable-type-img {
    position: absolute;
    height: 100%;
    z-index: -1;
    left: 0;
    top: 0;
    width: 30%;
    -webkit-mask-image: -webkit-gradient(
      linear,
      left top,
      left right,
      from(rgba(0, 0, 0, 1)),
      to(rgba(0, 0, 0, 0))
    );
    mask-image: linear-gradient(to right, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0));
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  .deliverable-title {
    text-transform: uppercase;
  }

  .brief {
    color: #cfd8dc !important;
  }

  .deliverable-status {
    transform: rotate(45deg);
    background-color: red;
    color: white;
    position: absolute;
    right: -40px;
    width: 150px;
    top: 0;
    text-transform: uppercase;
    /* overflow: hidden; */
    /* margin: 0 auto; */
    text-align: center;
    top: 22px;
  }
}
</style>