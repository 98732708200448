<template>
  <div class="service-filters">
    <h2 class="white--text">FILTERS</h2>
    <HelpFormInputVue
      dark
      v-model="pSearch"
      :timeout="300"
      label="Search"
      :helpText="'Provide any text to search across services'"
      :tooltipAttrs="{
        left: true,
      }"
    ></HelpFormInputVue>
    <SelectorDefault
      dark
      label="Deliverable Type"
      v-model="pType"
      :items="deliverableTypes"
      :helpText="'You can select deliverable type to be able operate with current deliverable across the system. Please note that this change can not be undone.'"
      required
      clearable
    ></SelectorDefault>
  </div>
</template>


<script>
import SelectorDefault from "../../atoms/common/autocompletes/SelectorDefault.vue";
import HelpFormInputVue from "../../atoms/common/inputs/HelpFormInput.vue";

export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      pSearch: this.value.search ? this.value.search : undefined,
      pType: this.value.type ? this.value.type : undefined,
      deliverableTypes: [
        {
          name: "Activity",
          value: "activity",
          description:
            "This is some activity that will be done by ADAAS Team as a part of service processing.<br> This is might be some kind of meetings, review, audit or anything else.",
        },
        {
          name: "Document",
          value: "document",
          description:
            "This is something that ADAAS Team will deliver as a document.<br> This is might be diagram, mind map, actual Document, or even spreadsheet.",
        },
        {
          name: "SLA",
          value: "sla",
          description: `SLA types is something that ADAAS Team will guarantee during service processing. <br>
            This is might be response time, documents structure, or anything else that impacts delivery process.`,
        },
        {
          name: "Artifact",
          value: "artifact",
          description: `Artifact type is some sort of deliverable that should be created and provided to the customer.  <br>
          The main difference between artifact and document type that artifact is something more like code, or vision, or costs assessment, etc`,
        },
      ],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorDefault,
  },

  watch: {
    value: {
      handler(newVal) {
        this.filters = newVal;
      },
      deep: true,
    },
    filters: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },

    search(newVal) {
      this.pSearch = newVal;
    },

    pSearch(newVal) {
      this.$emit("input", {
        search: newVal,
      });
    },
    pType(newVal) {
      this.$emit("input", {
        type: newVal,
      });
    },
  },

  methods: {},
};
</script>


<style lang="scss" scoped>
.service-filters {
  // margin-right: 30px;
  padding: 40px;
  width: 100%;
}
</style>